<template>
  <BaseCenteredModal
    v-model:is-modal-open="localOpen"
    :title="$t('global.calendar_subscription_link')"
    size="medium"
    :translucent="false"
  >
    <template #body>
      <div class="flex flex-col gap-4">
        <div>
          <InfoPanel
            :description="$t('alert.calendar_subscription_link_explanation')"
            background-colored
          />
        </div>
        <div>
          <BaseDisabledNameInput
            :model-value="linkComputed"
            name="option"
            :option="true"
            @copy-var-name="handleCopyLink"
          />
        </div>
        <div class="rounded border p-4 pt-3">
          <h3 class="text-base font-semibold capitalize-first">
            {{ $t('global.options') }}
          </h3>
          <div class="grid w-full grid-cols-3 gap-3">
            <div>
              <SelectComponent
                v-model="start"
                label="De"
                name="end"
                :values="beforeDateValues"
                size="small"
                class="w-full"
                edition-mode
                required
              />
            </div>
            <div>
              <SelectComponent
                v-model="end"
                label="A"
                name="start"
                :values="afterDateValues"
                size="small"
                class="w-full"
                edition-mode
                required
              />
            </div>
            <div class="col-start-1">
              <WalkmanButton
                v-model="alert"
                :label="'notify'"
                size="small"
                :options="[
                  { value: 'alert', label: $t('enums.yesNo.1') },
                  { value: 'no-alert', label: $t('enums.yesNo.0') }
                ]"
                :edition-mode="true"
              />
            </div>
            <div>
              <SelectComponent
                v-model="alertBefore"
                label="A"
                name="start"
                :values="alertsValues"
                size="small"
                class="w-full"
                edition-mode
                required
              />
            </div>
            <div class="col-start-1">
              <WalkmanButton
                v-model="assignedPersons"
                :label="$t('global.assignedPeople')"
                size="small"
                :options="[
                  { value: 'hide', label: $t('enums.yesNo.1') },
                  { value: 'show', label: $t('enums.yesNo.0') }
                ]"
                :edition-mode="true"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseCenteredModal>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import BaseCenteredModal from '@c/addf-package/components/modal/BaseCenteredModal.vue'
import BaseDisabledNameInput from '@/views/settings/management/documents/form/tabs/documentInformation/BaseDisabledNameInput.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import { baseURL } from '@u/apiClient'
import WalkmanButton from '@c/WalkmanButton.vue'
import InfoPanel from '@ui/InfoPanel.vue'

export default defineComponent({
  name: 'GenerateOutlookLinkModal',
  components: {
    InfoPanel,
    WalkmanButton,
    SelectComponent,
    BaseDisabledNameInput,
    BaseCenteredModal
  },
  props: {
    dashboard: {
      type: Object,
      default: null
    },
    open: {
      type: Boolean,
      required: true
    }
  },
  emits: ['add-event-confirm', 'close', 'update:open'],
  data() {
    return {
      link: null,
      start: '-1week',
      end: '+2weeks',
      alert: 'alert',
      alertBefore: '1hour',
      assignedPersons: 'hide'
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth'
    }),
    localOpen: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    },
    beforeDateValues() {
      return [
        {
          id: '-6months',
          name: '-6 ' + this.$t('attributes.months')
        },
        {
          id: '-5months',
          name: '-5 ' + this.$t('attributes.months')
        },
        {
          id: '-4months',
          name: '-4 ' + this.$t('attributes.months')
        },
        {
          id: '-3months',
          name: '-3 ' + this.$t('attributes.months')
        },
        {
          id: '-2months',
          name: '-2 ' + this.$t('attributes.months')
        },
        {
          id: '-1month',
          name: '-1 ' + this.$t('attributes.month')
        },
        {
          id: '-3weeks',
          name: '-3 ' + this.$t('global.weeks')
        },
        {
          id: '-2weeks',
          name: '-2 ' + this.$t('global.weeks')
        },
        {
          id: '-1week',
          name: '-1 ' + this.$t('global.week')
        },
        {
          id: '-6days',
          name: '-6 ' + this.$t('attributes.days')
        },
        {
          id: '-5days',
          name: '-5 ' + this.$t('attributes.days')
        },
        {
          id: '-4days',
          name: '-4 ' + this.$t('attributes.days')
        },
        {
          id: '-3days',
          name: '-3 ' + this.$t('attributes.days')
        },
        {
          id: '-2days',
          name: '-2 ' + this.$t('attributes.days')
        },
        {
          id: '-1day',
          name: '-1 ' + this.$t('attributes.day')
        }
      ]
    },
    afterDateValues() {
      return [
        {
          id: '+6months',
          name: '+6 ' + this.$t('attributes.months')
        },
        {
          id: '+5months',
          name: '+5 ' + this.$t('attributes.months')
        },
        {
          id: '+4months',
          name: '+4 ' + this.$t('attributes.months')
        },
        {
          id: '+3months',
          name: '+3 ' + this.$t('attributes.months')
        },
        {
          id: '+2months',
          name: '+2 ' + this.$t('attributes.months')
        },
        {
          id: '+1month',
          name: '+1 ' + this.$t('attributes.month')
        },
        {
          id: '+3weeks',
          name: '+3 ' + this.$t('global.weeks')
        },
        {
          id: '+2weeks',
          name: '+2 ' + this.$t('global.weeks')
        },
        {
          id: '+1week',
          name: '+1 ' + this.$t('global.week')
        },
        {
          id: '+6days',
          name: '+6 ' + this.$t('attributes.days')
        },
        {
          id: '+5days',
          name: '+5 ' + this.$t('attributes.days')
        },
        {
          id: '+4days',
          name: '+4 ' + this.$t('attributes.days')
        },
        {
          id: '+3days',
          name: '+3 ' + this.$t('attributes.days')
        },
        {
          id: '+2days',
          name: '+2 ' + this.$t('attributes.days')
        },
        {
          id: '+1day',
          name: '+1 ' + this.$t('attributes.day')
        }
      ].reverse()
    },
    alertsValues() {
      return [
        {
          id: '5minutes',
          name: '5 ' + this.$t('global.minutes')
        },
        {
          id: '10minutes',
          name: '10 ' + this.$t('global.minutes')
        },
        {
          id: '15minutes',
          name: '15 ' + this.$t('global.minutes')
        },
        {
          id: '30minutes',
          name: '30 ' + this.$t('global.minutes')
        },
        {
          id: '1hour',
          name: '1 ' + this.$t('global.hour')
        },
        {
          id: '2hours',
          name: '2 ' + this.$t('global.hours')
        },
        {
          id: '1day',
          name: '1 ' + this.$t('global.day')
        },
        {
          id: '2days',
          name: '2 ' + this.$t('global.days')
        }
      ]
    },
    alertLinkComputed() {
      if (this.alert !== 'no-alert') {
        return this.alertBefore
      }
      return 'no-alert'
    },
    linkComputed() {
      let url = `https://${window.location.hostname}`
      if (process.env.NODE_ENV === 'development') {
        url = baseURL.substring(0, baseURL.length - 5)
      }
      return (
        `${url}/ical/${this.auth.id}/` +
        `dashboard/${this.dashboard.id}/` +
        `start-${this.start}/` +
        `end-${this.end}/` +
        `alert-${this.alertLinkComputed}/` +
        `assigned-people-${this.assignedPersons}/` +
        `calendars`
      )
    }
  },
  methods: {
    handleCopyLink() {
      navigator.clipboard.writeText(this.linkComputed)
      this.$h.toastSuccess(this.$t('toasts.copied_to_the_clipboard'))
    }
  }
})
</script>
