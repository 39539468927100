<template>
  <div
    v-if="editor"
    class="relative flex flex-row rounded-md border border-slate-300 shadow-sm"
    :class="{
       'pointer-events-none': disabled,
    }"
  >
    <div
      v-if="label"
      class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first"
    >
      {{ label }} <span v-if="required" class="text-red-600">*</span>
    </div>
    <div
      class="flex flex-1 flex-col"
      :class="{
        'bg-gray-100': disabled,
      }"
    >
      <div class="flex flex-row">
        <div
          v-if="displayFormatButton"
          class="flex flex-1 shrink-0 flex-row gap-2 p-2 pb-0"
        >
          <button
            class="rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('bold') }"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <FontAwesomeIcon :icon="['far', 'bold']" size="sm"/>
          </button>
          <button
            class="rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('italic') }"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <FontAwesomeIcon :icon="['far', 'italic']" size="sm"/>
          </button>
          <button
            class="rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('underline') }"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <FontAwesomeIcon :icon="['far', 'underline']" size="sm"/>
          </button>
          <button
            class="hidden rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('strike') }"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <FontAwesomeIcon :icon="['fal', 'strikethrough']" size="sm"/>
          </button>
          <button
            class="hidden rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('heading', { level: 1 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            <FontAwesomeIcon :icon="['fal', 'font']" size="sm"/>
          </button>
          <button
            class="hidden rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('heading', { level: 2 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <FontAwesomeIcon :icon="['fal', 'font']" size="sm"/>
          </button>
          <button
            class="hidden rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('heading', { level: 3 }) }"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            <FontAwesomeIcon :icon="['fal', 'font']" size="sm"/>
          </button>
          <button
            class="rounded border border-gray-300 px-2 py-1 leading-none hidden"
            :class="{ 'bg-gray-200': editor.isActive('paragraph') }"
            @click="editor.commands.setParagraph()"
          >
            <FontAwesomeIcon :icon="['far', 'paragraph']" size="sm"/>
          </button>
          <button
            class="hidden rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('bulletList') }"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <FontAwesomeIcon :icon="['fal', 'list-ul']" size="sm"/>
          </button>
          <button
            class="hidden rounded border border-gray-300 px-2 py-1 leading-none"
            :class="{ 'bg-gray-200': editor.isActive('orderedList') }"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <FontAwesomeIcon :icon="['fal', 'list-ol']" size="xs"/>
          </button>
        </div>
        <div v-if="$slots['cta']" class="shrink-0 p-2 pb-0">
          <slot name="cta"/>
        </div>
      </div>
      <div
        class="w-full flex-1"
        :class="{
        }"
      >
        <editor-content
          ref="ed"
          :editor="editor"
          class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        />
      </div>
    </div>
    <template v-if="errors && errors.length > 0">
      <div
        v-for="(error, index) in errors"
        :key="index"
        class="form-help text-red-600"
      >
        {{ $t(error, {attribute: $t(name)}) }}
      </div>
    </template>
  </div>
</template>

<script>
import {Editor, EditorContent, VueRenderer} from '@tiptap/vue-3'
import tippy from 'tippy.js'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Underline from '@tiptap/extension-underline'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import Mention from '@tiptap/extension-mention'
import MentionList from './components/MentionList.vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseWysiwyg',
  components: {
    FontAwesomeIcon,
    EditorContent
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    displayFormatButton: {
      type: Boolean,
      required: false,
      default: true
    },
    suggestionVariables: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      editor: null
    }
  },
  watch: {
    modelValue(newValue) {
      let html = this.editor.getHTML()
      if (html !== newValue) {
        this.editor.commands.setContent(newValue)
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class:
            'overflow-y-auto overflow-x-hidden prose prose-sm focus:outline-none focus:!border-0 !p-3 !w-full max-w-none min-h-[8rem]'
        }
      },
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Underline,
        Text,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention'
          },
          suggestion: {
            items: ({query}) =>
              this.suggestionVariables
                .filter((model) => {
                  let search = query.length ? query.toLowerCase() : ''
                  if (
                    search.length < 2 ||
                    (model.name &&
                      model.name.toLowerCase().indexOf(search.toLowerCase()) ===
                      -1)
                  ) {
                    return
                  } else {
                    return model.name
                  }
                })
                .slice(0, 10)
                .reduce((init, item) => {
                  init.push(item.name)
                  return init
                }, []),
            render: () => {
              let component
              let popup
              return {
                onStart: (props) => {
                  component = new VueRenderer(MentionList, {
                    props,
                    editor: props.editor
                  })
                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start'
                  })
                },
                onUpdate(props) {
                  component.updateProps(props)
                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect
                  })
                },
                onKeyDown(props) {
                  if (component && component.ref) {
                    return component.ref.onKeyDown(props)
                  }
                },
                onExit() {
                  popup[0].destroy()
                  component.destroy()
                }
              }
            }
          }
        })
      ],
      content: this.modelValue,
      onUpdate: () => {
        let value = this.editor.getHTML()

        if (value.match(/^<[a-z]>( )*<\/[a-z]>$/i)) {
          value = ''
        }

        this.$emit('update:modelValue', value)
        this.$emit('change', value)
      }
    })
  },
  methods: {
    setTextAtPosition(text) {
      let stringVar =
        '<span data-type="mention" class="mention" data-id="' +
        text.description +
        '">@</span>'
      this.editor.commands.insertContent(stringVar)
    }
  }
}
</script>

<style>
.ProseMirror,
.ProseMirror.ProseMirror-focused {
  border: 0 !important;
}
</style>
