import store from "@/store";
import DashboardTypes from "@/assets/enums/dashboard/DashboardTypes";

const TheSupplierOrdersPage = () =>
  import(
    /* webpackChunkName: "group-supplier-order" */ '@/views/supplierOrder/TheSupplierOrdersPage.vue'
  )

export const supplierOrderSubPages = [
  {
    path: 'supplier-order',
    children: [
      {
        path: '',
        name: 'supplier_order_page_route',
        component: TheSupplierOrdersPage,
        meta: {
          route_name: 'supplier_order_page_route_id'
        },
        children: [
          {
            path: '',
            beforeEnter: (from, to, next) => {
              let template = store.getters[
                'metadata/getTemplateDashboardByType'
                ](DashboardTypes.SUPPLIER_ORDER)
              if (Array.isArray(template) && template[0]) {
                next({
                  name: 'supplier_order_page_route_id',
                  params: { id: template[0].id }
                })
                return
              }
              next()
              return
            }
          },
          {
            path: ':id',
            name: 'supplier_order_page_route_id',
            component: TheSupplierOrdersPage
          }
        ]
      }
    ]
  },
]
