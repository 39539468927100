import apiClient from '@u/apiClient'
import original_state from './original_state'
import Functionality from '@/plugins/functionality'

export default {
  fetchAllMetadata({ commit }) {
    console.log('%c☕ metadata loading ', 'color: #E2AF00')
    return new Promise((resolve, reject) => {
      apiClient
        .get('metadata/all')
        .then((response) => {
          // await commit('setAllMetadata', response.data)
          commit(
            'dashboard/setFullColumnList',
            response.data.models.dashboard_columns,
            { root: true }
          )
          commit(
            'dashboard/setTemplateList',
            response.data.models.dashboard_templates,
            { root: true }
          )
          commit(
            'dashboard/setTemplate',
            response.data.models.dashboard_templates.find(
              (template) => template.is_default
            ),
            { root: true }
          )
          commit('setInitialMetadata', response.data)
          Functionality.setupModule(response.data.app_settings.functionalities)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  fetchMetadataScreen({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get('screen/metadata')
        .then((response) => {
          commit('setInitialMetadata', response.data)
          resolve(response.data)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },

  async fetchUsersMetadata({ commit }) {
    await apiClient
      .get('metadata/users')
      .then(async (response) => {
        await commit('setUsersMetadata', response.data)
      })
      .catch(async (reason) => {
        console.error('fetchUsersMetadata', reason)
      })
  },
  async refreshInitialMetadataModels({ commit }) {
    await apiClient
      .get(`metadata/refresh-model`)
      .then(async (response) => {
        // await console.log('refreshInitialMetadataModels', response)
        commit('setMetadataModels', response.data.models)
      })
      .catch(async (reason) => {
        await console.error('refreshInitialMetadataModels', reason)
      })
  },
  async refreshSpecificInitialMetadataModels({ commit }, payload) {
    await apiClient
      .get(`metadata/refresh-model?key=${payload.model}`)
      .then(async (response) => {
        await commit('setSpecificMetadataModels', {
          model: payload.model,
          data: response.data[payload.model]
        })
      })
      .catch(async (reason) => {
        await console.error('refreshInitialMetadataModels', reason)
      })
  },
  refreshSettingsMetadata({ commit }) {
    return new Promise((resolve, reject) => {
      apiClient
        .get(`metadata/refresh-settings`)
        .then((response) => {
          commit('setMetadataSettings', {
            settings: response.data.settings,
            fh_settings: response.data.fh_settings
          })
          resolve(response)
        })
        .catch((reason) => {
          reject(reason)
        })
    })
  },
  async refreshMultipleInitialMetadataModels({ commit }, arrayOfModelsName) {
    await apiClient
      .get(`metadata/refresh-model?key=${arrayOfModelsName.join(',')}`)
      .then(async (response) => {
        // await console.log('refreshInitialMetadataModels', response)
        commit('setMutlipleMetadataModels', response.data)
      })
      .catch(async (reason) => {
        await console.error('refreshInitialMetadataModels', reason)
      })
  },

  async addReadVersionNote({ commit }, payload) {
    await apiClient
      .put(`add-unread-version/`, payload.versions)
      .then(async (response) => {
        if (response.status === 200) {
          payload.successCallback()
        }
      })
      .catch(async (reason) => {
        console.error('updateInvoice reason', reason)
      })
  },
  resetState({ commit }) {
    commit('resetState', original_state)
  },
  setCurrentGlobalForm({ commit, state }, payload) {
    // console.log('setCurrentGlobalForm')
    // console.log('updateFormConfigSettings')
    // console.log(state.initialMetadata.form_settings[payload.funeral_home_id])
    // console.log('updateFormConfigFuneralHomeId')
    // console.log(payload.funeral_home_id)
    commit(
      'updateFormConfigSettings',
      state.initialMetadata.form_settings[payload.funeral_home_id]
    )
    commit('updateFormConfigFuneralHomeId', payload.funeral_home_id)
  },
  toggleGlobalFormEditionModeForFuneralHome({ commit, state }, payload) {
    commit(
      'updateFormConfigSettings',
      state.initialMetadata.form_settings[payload.funeral_home_id]
    )
    commit('updateFormConfigFuneralHomeId', payload.funeral_home_id)
    commit('toggleGlobalFormEditionMode', true)
  },
  restoreGlobalForm({ commit, state }, payload) {
    if (state.global_form.funeral_home_id !== payload.funeral_home_id) {
      commit(
        'updateFormConfigSettings',
        state.initialMetadata.form_settings[payload.funeral_home_id]
      )
      commit('updateFormConfigFuneralHomeId', payload.funeral_home_id)
    }
  },
  commitFormSettingToInitialMetadata({ commit, state }, payload) {
    state.initialMetadata.form_settings[payload.funeral_home_id] =
      payload.form_settings
  },
  //  USER STATUS
  changeUserOnlineStatus({ commit }, payload) {
    commit('changeUserOnlineStatus', payload)
  }
}
