<template>
  <div
    class="w-full"
    @mouseenter="$emit('hover:content', true)"
    @mouseleave="$emit('hover:content', false)"
  >
    <div class="fm-form-input" :class="label ? ' mt-2' : ''">
      <label v-if="label" :for="modelValue"
        >{{ label }}<span class="text-red-600"> *</span></label
      >
      <input
        type="text"
        :name="name"
        :id="modelValue"
        class="block w-full !pr-8"
        :class="option ? 'text-xs' : ''"
        :disabled="true"
        v-model="modelValue"
      />
      <div class="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <span
          class="inline-flex cursor-pointer items-center px-1 text-xs text-gray-800"
          @click="$emit('copyVarName', { name: modelValue, option: option })"
        >
          <FontAwesomeIcon
            :icon="['fal', 'copy']"
            size="lg"
            class="text-theme-success"
          />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseDisabledNameInput',
  components: { FontAwesomeIcon },
  props: {
    copyVarName: {},
    modelValue: {},
    name: {},
    label: {
      type: String,
      required: false,
      default: null
    },
    option: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['copyVarName', 'hover:content']
}
</script>
