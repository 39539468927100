<template>
  <template v-if="content !== null">
    <span v-if="!big" class="cursor-default p-3 truncate" :data-tint="color">
      <span class="rounded-md bg-primary px-2 py-1 text-contrast-primary">
        <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" class="mr-2" />
        <span v-if="content" class="capitalize-first truncate">{{ content }}</span>
      </span>
    </span>

    <div
      v-else
      :data-tint="color"
      class="flex select-none rounded-md bg-primary px-4 py-2.5 text-contrast-primary"
      aria-expanded="false"
    >
      <FontAwesomeIcon v-if="icon" :icon="['fal', icon]" />
      <span v-if="content" class="capitalize-first truncate">{{ content }}</span>
    </div>
  </template>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BaseLabel',
  components: { FontAwesomeIcon },
  props: {
    content: { type: String, required: true },
    color: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    big: { type: Boolean, required: false, default: false }
  },
  computed: {
    colorClass() {
      return this.color === '' ? 'bg-slate-300' : `bg-theme-${this.color}`
    }
  }
}
</script>
