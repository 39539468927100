<template>
  <div>
    <template v-if="editionMode">
      <div
        class="relative flex flex-row rounded-md border border-slate-300 shadow-sm focus-within:border-primary-400 focus-within:ring-0 dark:border dark:border-white"
        :class="[label ? 'mt-2' : '']"
      >
        <Listbox v-model="localLanguage">
          <label
            v-if="label"
            for="name"
            class="absolute -top-2 left-2 z-10 -mt-px inline-block rounded-md bg-white/75 px-1 text-xs font-medium text-gray-900 capitalize-first dark:bg-white dark:text-slate-700"
            :class="{
              'pointer-events-none': disabled
            }"
          >
            {{ label }}
            <span v-if="required" class="text-red-500">*</span>
          </label>
          <input
            v-if="fieldType === 'input'"
            v-model="localTranslations[localLanguage]"
            class="block flex-1 rounded-md border-0 p-0 px-3 py-2 text-gray-900 placeholder-gray-500 focus:ring-0 dark:bg-slate-500 dark:text-white sm:text-sm"
            :class="{
              'bg-gray-100': disabled,
              'cursor-no-drop': disabled
            }"
            type="text"
            :disabled="disabled"
          />
          <textarea
            v-else-if="fieldType === 'textarea'"
            id="name"
            v-model="localTranslations[localLanguage]"
            rows="4"
            :name="name"
            class="block flex-1 rounded-md border-0 p-0 px-3 py-2 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            style="min-height: 180px; max-height: 400px"
            :class="{
              'bg-gray-100': disabled,
              'cursor-no-drop': disabled,
              'pr-8': availableLanguagesWOCurrent.length > 0
            }"
            :disabled="disabled"
            v-html="localTranslations[localLanguage]"
          />

          <span
            v-if="missingTranslation && fieldType === 'input'"
            class="absolute inset-y-0 right-0 mr-14 flex items-center text-orange-400"
            :class="{
              'pointer-events-none': disabled
            }"
          >
            <FontAwesomeIcon :icon="['fas', 'exclamation-triangle']" />
          </span>
          <div
            class="absolute right-0 flex flex-row pr-1.5"
            :class="{
              'pt-2': fieldType === 'textarea',
              'inset-y-2 items-center': fieldType !== 'textarea',
              'pointer-events-none': disabled
            }"
          >
            <ListboxButton
              v-if="availableLanguagesWOCurrent.length > 0"
              class="txt-white rounded bg-slate-300 px-1.5 py-1 text-xs text-gray-700"
              tabindex="-1"
            >
              {{ localLanguage }}
            </ListboxButton>
          </div>

          <transition
            leave-active-class="transition duration-100 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-50 mt-10 flex max-h-60 w-full flex-col gap-1 overflow-auto rounded-md border border-slate-300 bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              <ListboxOption
                v-for="(language, index) in availableLanguagesWOCurrent"
                v-slot="{ active, selected }"
                :key="index"
                :value="language"
                as="template"
              >
                <li
                  class="relative cursor-pointer rounded-md px-3 py-2 hover:bg-gray-100"
                >
                  {{ localTranslations?.[language] }}&nbsp;
                  <div
                    class="absolute inset-y-0 right-0 flex flex-row items-center pr-1.5"
                  >
                    <span
                      class="rounded-md bg-slate-300 px-1 py-0.5 text-gray-700"
                    >
                      {{ language }}
                    </span>
                  </div>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </Listbox>
      </div>
      <ErrorContainer :error-key="name" :errors="mergedErrors" />
    </template>

    <template v-else>
      <BaseShowLabel
        :label="label"
        :model-value="localTranslations?.[localLanguage]"
      />
    </template>
  </div>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import BaseShowLabel from '../BaseLabel/BaseShowLabel.vue'
import { mapGetters } from 'vuex'
import ErrorContainer from '@c/addf-package/components/BaseShowEditInput/ErrorContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BaseWysiwyg from '@c/addf-package/components/Wysiwyg/BaseWysiwyg.vue'

export default {
  name: 'BaseShowEditTranslation',
  components: {
    BaseWysiwyg,
    FontAwesomeIcon,
    ErrorContainer,
    BaseShowLabel,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
  },
  props: {
    editionMode: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '',
      require: true
    },
    translations: {
      type: Object,
      require: true,
      default: () => {}
    },
    errors: {
      type: Array,
      default() {
        return []
      }
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    fieldType: {
      type: String,
      required: false,
      default: 'input'
    }
  },
  data() {
    return {
      localLanguage: null
    }
  },
  computed: {
    ...mapGetters({
      availableLanguages: 'metadata/getAvailableLanguages',
      initialMetadata: 'metadata/getInitialMetadata'
    }),
    localTranslations: {
      get() {
        return this.translations
      },
      set(value) {
        this.$emit('update:translations', value)
      }
    },
    availableLanguagesWOCurrent() {
      return this.availableLanguages.filter(
        (item) => item !== this.localLanguage
      )
    },
    mergedErrors() {
      if (!this.errors) {
        return null
      }
      let str_errors = Object.values(this.errors).flat().join()
      const pattern = /attributes\.\*/
      if (pattern.test(str_errors)) {
        return [str_errors.replace(pattern, this.$t('attributes.' + this.name))]
        // return str_errors.replace('attributes', this.$t('attributes.name'))
      }
      return Object.values(this.errors).flat()
    },
    missingTranslation() {
      return this.availableLanguages.some((language) => {
        return (
          this.translations[language] === undefined ||
          this.translations[language] === ''
        )
      })
    }
  },
  mounted() {
    this.localLanguage = this.$i18n.locale
  },
  methods: {}
}
</script>
