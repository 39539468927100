<template>
  <transition
    enter-active-class="duration-500 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-100"
  >
    <div
      v-if="all_is_loaded"
      class="bg-gradient-to-b from-primary to-primary-700 dark:from-primary-900 dark:to-primary-700"
    >
      <TheMobileMenu :dashboard-layout="dashboardLayout" layout="side-menu" />
      <div class="side-nav-gradient flex h-screen overflow-hidden">
        <!-- BEGIN: Side Menu -->
        <div class="hidden flex-col md:flex print:hidden">
          <nav
            class="side-nav flex-1 overflow-x-hidden transition-width duration-300 ease-in-out"
            :style="sideNavStyles"
          >
            <a href="" class="mt-3 flex items-center justify-center pt-4">
              <img :src="brand_logo" width="100" alt="logo-fm" />
            </a>
            <div class="side-nav__devider my-6" />
            <MainMenu :key="keyForRefreshMenu" />
          </nav>
          <div class="flex-shrink-0">
            <div class="cursor-pointer pb-5 pl-5">
              <FontAwesomeIcon
                :icon="menuCollapseIcon"
                size="lg"
                class="text-white"
                @click="toggleCollapsed"
              />
            </div>
            <div class="flex border-t p-4">
              <InfoUserModalComponent />
            </div>
          </div>
        </div>
        <!-- END: Side Menu -->
        <!-- BEGIN: Content -->
        <div class="content flex flex-col">
          <TheTopBar @ask-for-refresh="incrementKeyForRefresh" />
          <div class="min-h-0 flex-1 p-6">
            <RouterView :key="keyForRefresh" />
          </div>
        </div>
        <!-- END: Content -->
      </div>
    </div>
  </transition>
  <transition
    leave-active-class="duration-500 ease-out"
    leave-from-class="transform opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div
      v-if="!all_is_loaded"
      id="loading-image"
      class="inset-0 flex h-screen w-screen flex-col items-center justify-center gap-5"
    >
      <img class="-intro-y" :src="brand_logo_full" width="250" />
      <div class="intro-y rounded-xl bg-white px-5 py-1.5 font-bold">
        {{ loadingMessage }}
      </div>
    </div>
  </transition>
  <FormInputConfigurator />
  <NotificationsContainer />
  <UnreadedVersionNoteModal
    :open="modalVersionNote"
    :versions="getInitialMetadata?.models?.unreaded_version_notes"
    @close-modal-version="closeUnreadedVersionNoteModal"
  />
</template>

<script>
import { onMounted } from 'vue'

import fm_logo from '../../../assets/images/funeral-manager-fm-only-colored.svg'
import fm_logo_full from '../../../assets/images/funeral-manager-full.svg'
import funero_logo from '../../../assets/images/funero-logo-simple.svg'
import funero_logo_full from '../../../assets/images/funero-logo-full.svg'

import TheMobileMenu from '../mobile-menu/TheMobileMenu'
import TheTopBar from '../top-bar/TheTopBar'

import InfoUserModalComponent from '../top-bar/InfoUserModalComponent'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SendEMailModal from '@ui/modal/email/SendEMailModal'
import UnreadedVersionNoteModal from '@ui/modal/versionNote/UnreadedVersionNoteModal.vue'
import NotificationsContainer from '@/views/layouts/main-layout/ToastsContainer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FormInputConfigurator from '@c/FormInputConfigurator.vue'
import i18n from '@/i18n'
import MainMenu from '@/views/layouts/main-layout/MainMenu.vue'

export default {
  components: {
    MainMenu,
    FormInputConfigurator,
    FontAwesomeIcon,
    NotificationsContainer,
    UnreadedVersionNoteModal,
    SendEMailModal,
    TheTopBar,
    TheMobileMenu,
    InfoUserModalComponent
  },
  data() {
    return {
      loadingMessage: '',
      keyForRefresh: 0,
      keyForRefreshMenu: 0
    }
  },
  setup() {
    onMounted(() => {
      document.body.classList.remove('error-page')
      document.body.classList.remove('login')
      document.body.classList.add('main')
      document.querySelectorAll('#app > div').forEach((element) => {
        element.classList.remove('content')
      })
    })
    return {}
  },
  computed: {
    ...mapGetters({
      collapsedMenu: 'sideMenu/collapsedMenu',
      getMetadataLoaded: 'metadata/getMetadataLoaded',
      getInitialMetadata: 'metadata/getInitialMetadata',
      modalVersionNote: 'metadata/getOpenModalVersionNote',
      functionalities: 'metadata/getFunctionalities',
      authUser: 'auth/getUser'
    }),
    all_is_loaded() {
      return this.authUser !== null && this.getMetadataLoaded
    },
    brand_logo() {
      if (this.$brand === 'funero') {
        return funero_logo
      }
      return fm_logo
    },
    brand_logo_full() {
      if (this.$brand === 'funero') {
        return funero_logo_full
      }
      return fm_logo_full
    },
    menuCollapseIcon() {
      return [
        'fal',
        this.collapsedMenu ? 'chevron-double-right' : 'chevron-double-left'
      ]
    },
    sideNavStyles() {
      return this.collapsedMenu ? { width: '105px' } : {}
    }
  },
  mounted() {
    this.loadUser()
    setTimeout(async () => {
      this.processToggleModalVersionNoteMutation(
        this.getInitialMetadata?.models?.unreaded_version_notes?.length > 0
      )
    }, 2000)
    this.emitter.on('reload-menu', () => {
      console.log('reload-menu')
      this.keyForRefreshMenu += 1
    })
  },
  methods: {
    ...mapActions('sideMenu', ['toggleCollapsed']),
    ...mapActions({
      refreshAuth: 'auth/refreshAuth',
      refreshNotificationCenter: 'notification/fetchNotifications',
      fetchMetadata: 'metadata/fetchAllMetadata',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels',
      processGetAuthUserAction: 'auth/getAuthUser'
    }),
    ...mapMutations({
      processToggleModalVersionNoteMutation: 'metadata/toggleModalVersionNote',
      setPermissions: 'auth/setPermissions',
      setAuthUser: 'auth/setAuthUser'
    }),
    loadUser() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ name: 'login' })
      }
      this.loadingMessage = this.$t('global.loading', 'loading')
      this.processGetAuthUserAction()
        .then((response) => {
          this.setAuthUser(response.user)
          this.loadPermission()
        })
        .catch((_reason) => {
          this.$router.push({ name: 'login' })
        })
    },
    loadPermission() {
      this.loadingMessage = this.$t('global.loading_permissions', 'loading')
      this.setPermissions()
      if (this.authUser.language !== this.$i18n.locale) {
        this.loadUserLocale()
      } else {
        this.loadMetadata()
      }
    },
    loadUserLocale() {
      this.loadingMessage = this.$t('global.loading_locale', 'loading')
      i18n.setLocale(this.authUser.language).then((_) => {
        this.loadMetadata()
      })
    },
    loadMetadata() {
      this.loadingMessage = this.$t('global.loading_data', 'loading')
      this.fetchMetadata()
    },
    closeUnreadedVersionNoteModal() {
      this.processToggleModalVersionNoteMutation(false)
    },
    incrementKeyForRefresh() {
      this.keyForRefresh += 1
    }
  }
}
</script>
