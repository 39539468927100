<template>
  <BaseSlideOverModal
    v-model:is-modal-open="open"
    width="xl"
    :title="getTitle"
    @close="close"
  >
    <template #cta>
      <div v-if="localDashboard" class="mt-1 flex h-full gap-x-1">
        <BaseButton
          v-if="localDashboard?.id && localDashboard.creator_id === auth.id && $can('manage_dashboards')"
          :title="$t('button.delete')"
          icon="trash"
          size="small"
          color="danger"
          @click="deleteTemplateDashboard"
        />
        <BaseButton
          v-if="$can('manage_dashboards')"
          :title="$t(localDashboard?.id ? 'button.edit' : 'button.add')"
          icon="paper-plane"
          class="dark:border-primary-500 dark:bg-slate-700 dark:text-primary-300 dark:hover:bg-primary-900"
          size="small"
          @click="submitForm"
        />
      </div>
    </template>
    <template #body>
      <form
        class="-mx-0.5 w-full overflow-y-auto"
        @submit.prevent="submitForm"
      >
        <div class="flex flex-col gap-2">
          <template
            v-if="action === 'new' && templateDashboardPublic.length > 0"
          >
            <div class="flex flex-row gap-2">
              <WalkmanButton
                v-model="newType"
                :options="dashboard_creation_types"
                track-by="id"
                attribute-label="name"
                size="extra-small"
                w-full
              />
            </div>
          </template>
          <div v-if="!getDisplayForm" class="flex flex-col gap-y-5 px-0.5">
            <DashboardPublic
              :dashboards="templateDashboardPublic"
              :type-dashboard-available="typeDashboardAvailable"
              @subscribed="publicSubscribed"
            />
          </div>
          <div v-else-if="localDashboard" class="flex flex-col gap-y-5 px-0.5">
            <div class="grid grid-cols-2 gap-3">
              <div class="col-span-2">
                <SelectComponent
                  v-if="!localDashboard.id && typeDashboardAvailable.length > 1"
                  v-model="localDashboard.type"
                  :label="$t('attributes.type')"
                  :name="'type'"
                  :values="template_dashboard_types_filtered"
                  :required="true"
                />
                <BaseShowLabel
                  v-else
                  class="capitalize-first"
                  :label="$t('attributes.type')"
                  :model-value="
                    template_dashboard_types_filtered.find(
                      (item) => item.id === localDashboard.type
                    )?.name
                  "
                />
              </div>
            </div>

            <template v-if="localDashboard.type">
              <div class="grid grid-cols-2 gap-3">
                <div class="col-span-2">
                  <BaseShowEditTranslation
                    v-model:translations="localDashboard.name"
                    :label="$t('attributes.name')"
                    name="name"
                    :edition-mode="true"
                    :required="true"
                  />
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <h2 class="mt-2 flex-shrink-0 text-lg font-bold leading-none">
                  {{ $t('global.sharing_options') }}
                </h2>
                <div
                  class="flex flex-col gap-3 rounded-md bg-gray-50 p-2 dark:border dark:border-white dark:bg-slate-700 dark:text-white"
                >
                  <div class="flex flex-row gap-2">
                    <div class="w-1/2">
                      <BaseCheckBox
                        v-model="localDashboard.public"
                        :label="$t('global.public')"
                        :edition-mode="true"
                      />
                    </div>

                    <div
                      v-if="
                        localDashboard.public || localDashboard.public === 1
                      "
                      class="w-1/2"
                    >
                      <BaseCheckBox
                        v-model="localDashboard.locked"
                        :label="$t('global.locked')"
                        :edition-mode="true"
                      />
                    </div>
                  </div>
                  <div class="">
                    <UsersSelectorComponent
                      v-model:users-id="localDashboard.subscriber_ids"
                      :title="$t('global.subscribers')"
                      :edition-mode="true"
                    />
                  </div>
                </div>
              </div>

              <template v-if="localDashboard.type === 'agenda'">
                <div class="grid grid-cols-2 gap-3">
                  <h2 class="col-span-2 mt-2 text-lg font-bold leading-none">
                    {{ $t('global.display_options') }}
                  </h2>
                  <div class="col-span-2 rounded-md bg-gray-50 p-2">
                    <DisplayOptionsContent
                      v-model:display-options="localDashboard.display_options"
                    />
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </form>
    </template>
  </BaseSlideOverModal>
  <ConfirmModal ref="deleteTemplateDashboard" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseSlideOverModal from '@c/addf-package/components/modal/BaseSlideOverModal.vue'
import SelectComponent from '@c/BaseFormComponent/SelectComponent.vue'
import BaseCheckBox from '@c/addf-package/components/BaseCheckBox/BaseCheckBox.vue'
import DisplayOptionsContent from '@c/Dashboard/Content/Agenda/Panel/DisplayOptionsContent.vue'
import BaseShowEditTranslation from '@c/addf-package/components/BaseShowEditInput/BaseShowEditTranslation.vue'
import UsersSelectorComponent from '@c/BaseSelectorComponent/UsersSelectorComponent.vue'
import WalkmanButton from '@c/WalkmanButton.vue'
import DashboardPublic from '@c/Dashboard/Components/DashboardComponent/DashboardPublic.vue'
import DashboardTypes from '@/assets/enums/dashboard/DashboardTypes'
import BaseShowLabel from '@c/addf-package/components/BaseLabel/BaseShowLabel.vue'
import BaseButton from '@c/addf-package/components/BaseButton/BaseButton.vue'
import ConfirmModal from '@c/addf-package/components/modal/ConfirmModal.vue'

let new_template_dashboard = {
  name: {},
  subscriber_ids: []
}

export default {
  name: 'DashboardModal',
  components: {
    ConfirmModal,
    BaseButton,
    BaseShowLabel,
    DashboardPublic,
    WalkmanButton,
    UsersSelectorComponent,
    BaseShowEditTranslation,
    DisplayOptionsContent,
    BaseCheckBox,
    SelectComponent,
    BaseSlideOverModal
  },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    dashboard_id: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: false,
      default: 'new'
    },
    dashboard_template: {
      type: Object,
      required: false,
      default: null
    },
    typeDashboardAvailable: {
      type: Array,
      required: false,
      default: () => Object.values(DashboardTypes)
    }
  },
  emits: ['close', 'dashboard-changed'],
  data() {
    return {
      localDashboard: { ...new_template_dashboard },
      newType: this.$can('manage_dashboards') ? 'new' : 'shared',
      templateDashboardPublic: [],
      selectedTemplateDashboardPublic: null
    }
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth',
      template_dashboard_types: 'metadata/getTemplateDashboardTypes',
      dashboard_creation_types: 'metadata/getDashboardCreationTypes',
      availableFilterAgenda: 'metadata/getAvailableFilterAgenda'
    }),
    template_dashboard_types_filtered() {
      return this.template_dashboard_types.filter((item) =>
        this.typeDashboardAvailable.includes(item.id)
      )
    },
    getTitle() {
      if (
        this.localDashboard?.name &&
        this.localDashboard.name[this.auth.language] &&
        this.localDashboard.name[this.auth.language].length
      ) {
        return this.localDashboard.name[this.auth.language]
      }
      return 'new'
    },

    getDisplayForm() {
      return !(this.action === 'new' && this.newType === 'shared')
    }
  },
  watch: {
    newType(newValue, _oldValue) {
      if (newValue === 'new') {
        this.initNewDashboard()
      } else {
        this.localDashboard = null
      }
    },
    open(newValue, oldValue) {
      if (newValue) {
        if (this.action === 'new') {
          this.initNewDashboard()
        } else if (this.action === 'edit' && this.dashboard_id) {
          this.processFetchTemplateDashboardByIdAction({
            id: this.dashboard_id
          }).then(
            (response) => {
              this.localDashboard = response.data.template_dashboard
            }
          )
        } else if (this.action === 'duplicate' && this.dashboard_id) {
          this.processFetchTemplateDashboardByIdAction({
            id: this.dashboard_id
          }).then((response) => {
            const {
              id,
              subscriber_ids,
              name_index,
              creator,
              filters,
              updated_at,
              ...clone
            } = response.data.template_dashboard

            this.localDashboard = {
              ...clone,
              creator_id: this.auth.id,
              subscriber_ids: [],
              filters: []
            }
          })
        }
      }
    }
  },
  methods: {
    ...mapActions({
      processFetchTemplateDashboardByIdAction:
        'dashboard/fetchTemplateDashboardById',
      processCreateTemplateDashboardAction: 'dashboard/createTemplateDashboard',
      processDuplicateTemplateDashboardAction: 'dashboard/duplicateTemplateDashboard',
      processUpdateTemplateDashboardByIdAction:
        'dashboard/updateTemplateDashboardById',
      processFetchTemplateDashboardPublicAction:
        'dashboard/fetchTemplateDashboardPublic',
      processRefreshSpecificInitialMetadataModelsAction:
        'metadata/refreshSpecificInitialMetadataModels',
      processDeleteTemplateDashboardByIdAction:
        'dashboard/deleteTemplateDashboardById',
      processUndoDeleteTemplateDashboardByIdAction:
        'dashboard/undoDeleteTemplateDashboardById'
    }),
    ...mapGetters({
      auth: 'auth/getAuth'
    }),
    initNewDashboard() {
      this.localDashboard = { ...new_template_dashboard }
      // si un seul type de dashboard est passé en props, on le selectionne par défaut
      if (this.typeDashboardAvailable.length === 1) {
        this.localDashboard.type = this.typeDashboardAvailable[0]
      }
      // on ajoute le user courant dans l'array de subscriber id
      if (
        !this.localDashboard.subscriber_ids ||
        this.localDashboard.subscriber_ids.length === 0
      ) {
        this.localDashboard.subscriber_ids = [this.auth.id]
      }
      this.processFetchTemplateDashboardPublicAction().then((response) => {
        if (
          response.data.template_dashboards &&
          response.data.template_dashboards.length
        ) {
          this.templateDashboardPublic = response.data.template_dashboards
        } else {
          this.localDashboard = { ...new_template_dashboard }
          this.localDashboard.subscriber_ids.push(this.auth.id)
        }
      })
    },
    submitForm() {
      // si on a un ID, on update
      if (this.localDashboard.id) {
        this.processUpdateTemplateDashboardByIdAction({
          id: this.localDashboard.id,
          data: this.localDashboard
        }).then((response) => {
          this.localDashboard = response.data.template_dashboard
          this.processRefreshSpecificInitialMetadataModelsAction({
            model: 'template_dashboards'
          })
          this.$emit('dashboard-changed', this.localDashboard.id)
          this.$h.toastSuccess('dashboard_updated')
          this.close()
        })
        // si pas, on ajoute
      } else {
        if (this.action === 'duplicate') {
          this.processDuplicateTemplateDashboardAction({
            data: {
              ...this.localDashboard,
              target_template_id: this.dashboard_id
            }
          }).then((response) => {
            this.localDashboard = response.data.template_dashboard
            this.processRefreshSpecificInitialMetadataModelsAction({
              model: 'template_dashboards'
            })
            this.$emit('dashboard-changed', this.localDashboard.id)
            this.$h.toastSuccess(this.$t('toasts.dashboard_added'))
            this.close()
          })
        } else {
          this.processCreateTemplateDashboardAction({
            data: this.localDashboard
          }).then((response) => {
            this.localDashboard = response.data.template_dashboard
            this.processRefreshSpecificInitialMetadataModelsAction({
              model: 'template_dashboards'
            })
            this.$emit('dashboard-changed', this.localDashboard.id)
            this.$h.toastSuccess(this.$t('toasts.dashboard_added'))
            this.close()
          })
        }
      }
    },
    async deleteTemplateDashboard() {
      const confirmDeletion =
        this.localDashboard.subscriber_ids.length > 1 ||
        (this.localDashboard.subscriber_ids.length === 1 &&
          this.localDashboard.subscriber_ids[0] !== this.auth.id)
          ? await this.$refs.deleteTemplateDashboard.show({
            title: this.$t('toasts.warning_delete_template_dashboard'),
            okButton: this.$t('button.delete')
          })
          : true

      if (!confirmDeletion) return
      this.processDeleteTemplateDashboardByIdAction({
        id: this.localDashboard.id
      }).then((response) => {
        this.processRefreshSpecificInitialMetadataModelsAction({
          model: 'template_dashboards'
        })
        this.close()
        this.$h.toastAction(
          'delete',
          this.$t('toasts.deleted_m', { var: this.$t('global.dashboard') }),
          null,
          [
            {
              title: this.$t('button.cancel'),
              class: 'text-red-700',
              action: () => {
                this.undoDeleteTemplateDashboard(this.localDashboard.id)
              }
            }
          ]
        )
      })
    },
    undoDeleteTemplateDashboard(id) {
      this.processUndoDeleteTemplateDashboardByIdAction({
        id: id
      }).then((response) => {
        this.$h.toastSuccess(
          this.$t('toasts.reseted_m', { var: this.$t('global.dashboard') })
        )
        this.processRefreshSpecificInitialMetadataModelsAction({
          model: 'template_dashboards'
        })
      })
    },
    publicSubscribed(template_dashboard) {
      this.processRefreshSpecificInitialMetadataModelsAction({
        model: 'template_dashboards'
      })
      this.$emit('dashboard-changed', template_dashboard.id)
      this.$h.toastSuccess('dashboard_subscribed')
      this.close()
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
